import React from 'react';
import './styles/App.css';
import Header from './components/Header';
import Modal from './components/Modal';
import Footer from './components/Footer';
import PageWrapper from './components/PageWrapper';
import AppWrapper from './components/AppWrapper';
import Start from './pages/Start';
import MapMeID from './pages/MapMeID';
import Form1 from './pages/Form1';
import EnterYourDetails from './pages/EnterYourDetails';
import Confirmation from './pages/Confirmation';
import Results from './pages/Results';
import HealthyWeightInformation from './pages/HealthyWeightInformation';
import ExpandChild from './components/ExpandChild';
import ExpandAdult from './components/ExpandAdult';
import SelectChild from './pages/SelectChild';
import data from './data.json';
import {
  isMobile
} from "react-device-detect";
import { BrowserRouter as Router } from "react-router-dom";
//import queryString from 'query-string';
const url = new URL(window.location.href);
const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};


function App() {
  var HeaderRef = React.createRef();
  var FooterRef = React.createRef();
  var ModalRef = React.createRef();
  var ExpandChildRef = React.createRef();
  var ExpandAdultRef = React.createRef();
  var PageWrapperRef = React.createRef();
  var AppWrapperRef = React.createRef();
  var page = "Start";
  var prev_page;
  var pages = {};
  var keynav = false;
  var unloaded = false;
  var count, tracker;
  var imgtype = "png";
  var resizeTimer = null;
  var xmlhttp
  var session_log = {
    "id": "MapMe",
    "state": 0,
    "uid": "NONE",
    "sid": "S" + Math.round(Math.random() * 100000000000000),
    "sex": null,
    "a": null,
    "ag": null,
    "cen": null,
    "wca": null,
    "wcp": null,
    "wd": 0,
    "r": [],
    "e": false,
    "plat": getDeviceType(),
    "os": window.navigator.platform,
    "p": 0,
    "links": []
  }
  pages["StartRef"] = React.createRef();
  pages["MapMeIDRef"] = React.createRef();
  pages["Form1Ref"] = React.createRef();
  pages["SelectChildRef"] = React.createRef();
  pages["EnterYourDetailsRef"] = React.createRef();
  pages["ConfirmationRef"] = React.createRef();
  pages["ResultsRef"] = React.createRef();
  pages["HealthyWeightInformationRef"] = React.createRef();

  function supportWebp() {
    var elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    else {
      return false;
    }
  }
  if (supportWebp()) {
    imgtype = 'webp';
  }
  function browserBack(target) {
    pages[page + "Ref"].current.setState({ 'AnimationState': 'mapme-fadeout' });
    pages[target + "Ref"].current.setState({ 'AnimationState': 'mapme-fadein' });
    prev_page = page;
    page = target;
    setNavigationState();
  }
  function navigate(target, backtrack) {
    switch (page) {
      case "MapMeID":
        session_log.uid = pages["MapMeIDRef"].current.state.MapMeID;
        session_log.sex = pages["Form1Ref"].current.state.sex;
        session_log.ag = pages["Form1Ref"].current.state.age;
        antbitsLog(true, true);
        break;
      case "HealthyWeightInformation":
        antbitsLog(true, true);
        break;
      case "SelectChild":
        session_log.wcp = pages["SelectChildRef"].current.state.ChildWeightCat;
        antbitsLog(true, true);
        break;
      case "EnterYourDetails":
        antbitsLog(true, true);
        break;
      default:
        antbitsLog(true, false);
        break;
    }
    pages.ResultsRef.current.resetAnimation();
    var page_id = pages[page + "Ref"].current.state.page_id
    pages[page + "Ref"].current.setState({ 'AnimationState': 'mapme-fadeout' });
    pages[target + "Ref"].current.setState({ 'AnimationState': 'mapme-fadein' });
    prev_page = page;
    page = target;
    if (!backtrack) {
      console.log(page + 'pushed to history')
      window.history.pushState({ 'page_id': page_id }, null, page)
    } else {
      window.history.back();
    }
    setNavigationState();
  }
  function setNavigationState() {
    HeaderRef.current.setState({ 'page': page })
    if (page === 'Start') {
      AppWrapperRef.current.setState({ 'FooterStyle': 'mapme-footer-fadein' })
      HeaderRef.current.setState({ 'AnimationState': 'mapme-fadeout' });
    } else {
      AppWrapperRef.current.setState({ 'FooterStyle': 'mapme-footer-fadeout' })
      HeaderRef.current.setState({ 'AnimationState': 'mapme-fadein' });
    }
    if (page === 'Results' && prev_page === 'Confirmation') {
      AppWrapperRef.current.setState({ 'FooterStyle': 'mapme-footer-fadein' })
      PageWrapperRef.current.setState({
        preload: 'mapme-fadein',
      });
      pages["ResultsRef"].current.calcResult()
    } else {
      PageWrapperRef.current.setState({
        preload: 'mapme-fadeout'
      });
    }
    if (isMobile && (page !== 'Start' && page !== 'Results')) {
      document.body.classList.add('mapme-bgwhite')
    } else {
      document.body.classList.remove('mapme-bgwhite')
    }
    TrackPageHeight(300)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.getElementById('mapme-Start').removeAttribute('style')
    setTimeout(function () {
      SetPageStates(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (keynav) {
        if (page === 'Start') {
          document.getElementById('mapme-start-button').focus()
        } else {
          document.querySelector('#mapme-' + page + ' .mapme-app-back-button').focus()
        }
      }
    }, 300)
  }
  function setPreloadState() {
    pages["SelectChildRef"].current.setState({ 'preloaded': true });
  }
  function getFileName(cat, rotation) {
    var filename = 'images/placeholder.png';
    if (pages["Form1Ref"].current != null && pages["SelectChildRef"].current.state.preloaded) {
      if (pages["Form1Ref"].current.state.sex != null && pages["Form1Ref"].current.state.age != null) {
        filename = 'assets/3d/' + pages["Form1Ref"].current.state.sex + '-' + pages["Form1Ref"].current.state.age + '-' + cat + '-' + rotation + '.' + imgtype
      }
    }
    return filename;
  }
  function getAdultImg(cat, rotation, active) {
    var filename = 'images/placeholder.png';
    if (page === 'Results' && active && cat != null && rotation != null) {
      filename = 'assets/3d/' + pages["Form1Ref"].current.state.sex + '-AD-' + cat + '-' + rotation + '.' + imgtype
    }
    return filename;
  }
  function antbitsLog(send_analytics, send_result, obj) {
    for (const property in obj) {
      session_log[property] = obj[property];
    }
    session_log.p = page;
    session_log.r = page === 'HealthyWeightInformation' ? pages['HealthyWeightInformationRef'].current.state.resourcesVisited : pages['ResultsRef'].current.state.resourcesVisited
    if (send_analytics) {
      var vals = ['id', 'p', 'sex', 'ag', 'plat', 'e', 'wca', 'wcp', 'wd'];
      var url = 'https://preview.antbits.com/tracking/tracker.gif?'
      for (const property in vals) {
        if (session_log[vals[property]] !== null) {
          url += vals[property] + '=' + session_log[vals[property]] + '&'
        }
      }
      url += 'r=' + session_log['r'].join('|')
      xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", url, true);
      xmlhttp.send();
    }
    if (send_result) {
      vals = ["state", "uid", "sid", "sex", "a", "ag", "cen", "wca", "wcp", "wd", "r", "e", "plat", "os", "p"];
      url = 'https://mapmeuk.co.uk/analytics/'
      var output = {}
      for (const property in vals) {
        if (session_log[vals[property]] !== null) {
          output[vals[property]] = session_log[vals[property]];
        }
      }
      xmlhttp.open("POST", url, true);
      xmlhttp.send(JSON.stringify(output));
    }
  }
  window.addEventListener('popstate', (event) => {
    var forward = false;
    var ref = document.location.pathname.split('/').pop()
    if (event.state != null) {
      if (pages[page + 'Ref'].current.state.page_id <= event.state.page_id) {
        forward = true;
      }
    }
    ExpandChildRef.current.die();
    if (pages.hasOwnProperty(ref + 'Ref')) {
      if (forward && pages[page + 'Ref'].current.state.disabled) {
        event.preventDefault();
        window.history.go(-1);
      } else {
        browserBack(ref)
      }
    }

  });
  window.addEventListener('load', function () {
    var Params = new URLSearchParams(url.search);
    SetPageHeight();
    SetPageStates(page);
    var c = 0;
    for (var i in pages) {
      c++;
      pages[i].current.setState({ 'page_id': c });
    }
    if (Params.has('action')) {
      page = 'HealthyWeightInformation'
      session_log.p = 'HealthyWeightInformation'
      session_log.sid = Params.get('s')
      session_log.e = true
      navigate('HealthyWeightInformation', false);
      pages['HealthyWeightInformationRef'].current.setState({ 'Info': Params.get('assets').split(',') }, function () {
        TrackPageHeight(300);
      })
    } else {
      document.getElementById('mapme-Start').style.opacity = 1;
    }
    //testData()
  });
  window.addEventListener('focusout', function () {
    SetPageHeight();
  })
  window.addEventListener('resize', function () {
    SetPageHeight();
    document.getElementById("mapme-app").classList.add('mapme-resize');
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(function () {
      document.getElementById("mapme-app").classList.remove('mapme-resize');
    }, 300)
  });
  window.addEventListener('keyup', function () {
    //console.log(document.activeElement)
  });
  window.addEventListener('keyup', function (e) {
    if (e.code === 'Tab') {
      keynav = true;
      AppWrapperRef.current.setState({ 'KeynavStyle': 'mapme-keynav' })
    }
  });
  window.addEventListener('mousedown', function () {
    if (keynav) {
      keynav = false;
      AppWrapperRef.current.setState({ 'KeynavStyle': '' })
    }
  });
  window.unload = function () {
    // attempts to send analytics on page exit
    if (!unloaded) {
      unloaded = true;
      session_log['state'] = 1;
      session_log['unloaded'] = true;
      antbitsLog(true, true);
    }

  }
  window.onbeforeunload = function () {
    // attempts to send analytics on page exit
    if (!unloaded) {
      unloaded = true;
      session_log['state'] = 1;
      session_log['unloaded'] = true;
      antbitsLog(true, true);
    }
  }
  //document.getElementById("mapme-pages").on
  function lockRoot(val) {
    if (val) {
      AppWrapperRef.current.setState({ 'ExpandStyle': 'mapme-locked' })
      pages.StartRef.current.setState({ locked: true })
      pages.SelectChildRef.current.setState({ locked: true })
      pages.ResultsRef.current.setState({ locked: true })
      FooterRef.current.setState({ locked: true })
      if (page === 'SelectChild') {
        ExpandChildRef.current.setState({ 'active': true })
      }
      if (page === 'Results') {
        ExpandAdultRef.current.setState({ 'active': true })
      }
    } else {
      AppWrapperRef.current.setState({ 'ExpandStyle': '' })
      pages.StartRef.current.setState({ locked: false })
      pages.SelectChildRef.current.setState({ locked: false })
      pages.ResultsRef.current.setState({ locked: false })
      FooterRef.current.setState({ locked: false })
      if (page === 'SelectChild') {
        ExpandChildRef.current.setState({ 'active': false })
      }
      if (page === 'Results') {
        ExpandAdultRef.current.setState({ 'active': false })

      }
      this.SetPageHeight();
    }
  }
  function testData() {
    pages["Form1Ref"].current.testData()
    pages["SelectChildRef"].current.testData()
    setTimeout(function () {
      pages["EnterYourDetailsRef"].current.testData()
      navigate('SelectChild', false)
      setTimeout(function () {
        navigate('EnterYourDetails', false)
        /*setTimeout(function () {
          navigate('Results',false)
        }, 300)*/
      }, 300)
    }, 300)

  }
  function SetPageStates(show) {
    for (var i in pages) {
      if (i === show + 'Ref') {
        pages[i].current.setState({ 'visibility': 'visible' });
        pages[i].current.setState({ 'tabIndex': null });
      } else {
        pages[i].current.setState({ 'visibility': 'hidden' });
        pages[i].current.setState({ 'tabIndex': -1 });
      }
    }
  }
  function getWidth() {
    var w = window.innerWidth / parseFloat(getComputedStyle(document.querySelector('body'))['font-size'])
    return w
  }
  function NavBack() {
    var l = 'StartRef';
    for (var i in pages) {
      if (i === page + 'Ref') {
        break;
      } else {
        l = i;
      }
    }
    navigate(l.substring(0, l.length - 3), true);
  }
  function reset() {
    navigate('Start', false)
    for (var i in pages) {
      pages[i].current.reset();
    }
  }
  function SetPageHeight() {
    //console.log(page+' '+document.getElementById('mapme-' + page).clientHeight)
    document.body.height = window.innerHeight;
    PageWrapperRef.current.setState({
      h: document.getElementById('mapme-' + page).clientHeight
    });
    ExpandChildRef.current.setCarousel();
  }
  function TrackPageHeight(delay) {
    PageWrapperRef.current.setState({ 'animated': true })
    clearInterval(tracker)
    count = delay;
    tracker = setInterval(function () {
      if (count > 0) {
        count -= 5
        SetPageHeight()
      } else {
        PageWrapperRef.current.setState({ 'animated': false })
        clearInterval(tracker)

      }
    }, 5)
  }

  return (
    <Router basename="/">
      <AppWrapper ref={AppWrapperRef} imgtype={imgtype} setPreloadState={setPreloadState}>
        <Header ref={HeaderRef} NavBack={NavBack} page={page} />
        <PageWrapper ref={PageWrapperRef} page={page}>
          <Start data={data} navigate={navigate} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} ref={pages.StartRef} />
          <MapMeID data={data} navigate={navigate} antbitsLog={antbitsLog} Form1={pages["Form1Ref"]} reset={reset} SelectChild={pages["SelectChildRef"]} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} ref={pages.MapMeIDRef} />
          <Form1 data={data} navigate={navigate} SelectChild={pages["SelectChildRef"]} TrackPageHeight={TrackPageHeight} ref={pages.Form1Ref} />
          <SelectChild data={data} navigate={navigate} antbitsLog={antbitsLog} getFileName={getFileName} TrackPageHeight={TrackPageHeight} ExpandChild={ExpandChildRef} Form1={pages["Form1Ref"]} EnterYourDetails={pages["EnterYourDetailsRef"]} ref={pages.SelectChildRef} />
          <EnterYourDetails data={data} navigate={navigate} Form1={pages["Form1Ref"]} antbitsLog={antbitsLog} getFileName={getFileName} Confirmation={pages["ConfirmationRef"]} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} SelectChild={pages["SelectChildRef"]} ref={pages.EnterYourDetailsRef} />
          <Confirmation data={data} navigate={navigate} SetPageHeight={SetPageHeight} EnterYourDetails={pages["EnterYourDetailsRef"]} TrackPageHeight={TrackPageHeight} ref={pages.ConfirmationRef} />
          <Results data={data} navigate={navigate} antbitsLog={antbitsLog} reset={reset} getFileName={getFileName} getAdultImg={getAdultImg} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} MapMeID={pages["ReferenceNumberRef"]} SessionID={session_log.sid} Form1={pages["Form1Ref"]} EnterYourDetails={pages["EnterYourDetailsRef"]} SelectChild={pages["SelectChildRef"]} ExpandAdult={ExpandAdultRef} ref={pages.ResultsRef} />
          <HealthyWeightInformation data={data} navigate={navigate} antbitsLog={antbitsLog} getFileName={getFileName} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} SessionID={session_log.sid} ref={pages.HealthyWeightInformationRef} />
        </PageWrapper>
        <Footer data={data} ref={FooterRef} modal={ModalRef} page={page} />
        <ExpandChild data={data} ref={ExpandChildRef} keynav={keynav} lockRoot={lockRoot} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} getWidth={getWidth} getFileName={getFileName} SelectChild={pages["SelectChildRef"]} Form1={pages["Form1Ref"]} EnterYourDetails={pages["EnterYourDetailsRef"]} />
        <ExpandAdult data={data} ref={ExpandAdultRef} keynav={keynav} lockRoot={lockRoot} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} getWidth={getWidth} getAdultImg={getAdultImg} />
        <Modal data={data} ref={ModalRef} keynav={keynav} lockRoot={lockRoot} SetPageHeight={SetPageHeight} TrackPageHeight={TrackPageHeight} getWidth={getWidth} />
      </AppWrapper>
    </Router>
  );
}
export default App;