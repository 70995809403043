import React from 'react'
import { Component } from 'react'
import parse from 'html-react-parser';
class HealthyWeightInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AnimationState: "mapme-fadein-delayed",
            visibility: "visible",
            tabIndex: null,
            ResultInfoHead: this.props.data.text_areas.result_info_head,
            ResultInfoBody: this.props.data.text_areas.result_info_body,
            Info: [],
            InfoClasses: ['start', 'middle', 'end'],
            resourcesVisited: [],
            locked: false
        };
    }
    externalLink(url) {
        var mapObj = { 'https://': '', 'http://': '', 'www.': '', 'nhs.uk': '' };
        this.state.resourcesVisited.push(this.replaceAll(url, mapObj))
        this.setState({ 'resourcesVisited': Array.from(new Set(this.state.resourcesVisited)) }, function () {
            this.props.antbitsLog(true, true)
            console.log(this.state)
        })
    }
    replaceAll(str, mapObj) {
        var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
        return str.replace(re, function (matched) {
            return mapObj[matched.toLowerCase()];
        });
    }
    reset() { }
    render() {
        return (
            <div className={`mapme-page ${this.state.AnimationState}`} style={{ visibility: this.state.visibility }} id="mapme-HealthyWeightInformation">
                <div id="mapme-info">
                    <h2>{this.state.ResultInfoHead}</h2>
                    {this.state.ResultInfoBody}

                    <div>
                        {this.state.Info.map((value, index)=> {
                            var node = this.props.data.result_assets[value]
                            var c = 0;
                            if (typeof node !== 'undefined') {
                                if (node.image !== '') {
                                    var img = '../assets/results/' + node.image;
                                    return <div key={index} className={`mapme-info-${this.state.InfoClasses[c % 3]}`}><img alt={node.header} src={require('../assets/results/' + node.image).default} /><a href={node.url} target="_blank" onClick={() => this.externalLink(node.url)} tabIndex={this.state.locked ? -1 : null}>{node.header}</a><div>{parse(node.copy)}</div></div>
                                    c++;
                                }
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default HealthyWeightInformation;